<template>
  <div class="hotel-detail">
    <Menu></Menu>
    <!--<div>Rendered on {{renderedOn}}</div>-->
    <!--<router-link to="/">Home</router-link>-->
    <b-container>
      <b-row class="mb-4">
        <b-col>
          <br />
          <b>{{ hotel.category }} hotel</b>
          <h1>
            {{ hotel.brand }}
            {{ hotel.name | capitalize }}
          </h1>
          <div class="hotel-address">
            <a
              target="_blank"
              :href="
                'https://www.google.com/maps/place/?q=place_id:' +
                hotel.googlePlaceId
              "
            >
              <font-awesome-icon :icon="['fas', 'map-marker']" />
              {{ hotel.address }}
            </a>
            , &nbsp;
            <a :href="'tel:' + hotel.internationalPhoneNumber">
              <font-awesome-icon :icon="['fas', 'mobile-alt']" />
              {{ hotel.internationalPhoneNumber }}
            </a>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="9" sm="12">
          <img class="img-fluid rounded mb-5" :src="hotel.image" />
        </b-col>
        <b-col md="3" sm="12">
          <div>
            <b-row no-gutters class="mb-4">
              <b-col>
                <b-form-rating
                  size="sm"
                  v-model="hotel.rating"
                  no-border
                  readonly
                  color="#212529"
                ></b-form-rating>
              </b-col>
              <b-col>
                <div class="rating">
                  &nbsp;
                  <b-badge variant="dark">
                    {{ hotel.rating }}
                  </b-badge>
                  <span class="reviews text-uppercase">
                    {{ hotel.userRatingsTotal | formatNumber }}
                    reviews
                  </span>
                </div>
              </b-col>
            </b-row>

            <div v-if="this.dates.datasets[0].values.length > 0">
              <div class="price text-center">
                ${{ hotel.averageDailyRate | formatNumber }}
              </div>
              <div class="mb-4 text-center">per night on average</div>

              <div>
                Daily rates between:
                <span class="float-right">
                  ${{ hotel.minRate }}&ndash;${{ hotel.maxRate }}
                </span>
              </div>
              <div class="mb-5">
                Cheapest {{ hotel.tripLength }}-night stay:
                <span class="float-right">
                  ${{ hotel.minBaseTripPrice | formatNumber }}
                </span>
              </div>
            </div>
          </div>
          <b-button block variant="dark" target="_blank" :href="hotel.website"
            >Visit hotel website</b-button
          >
        </b-col>
      </b-row>

      <b-row v-if="this.dates.datasets[0].values.length > 0">
        <b-col>
          <h4>Daily price chart</h4>
          <div class="mb-3">
            Each point on this interactive chart represents the cheapest rate
            for a specific day. The best rate at this hotel hovers around ${{
              Math.round(hotel.minTripPrice / hotel.tripLength) | formatNumber
            }}
            per night.
          </div>

          <div>
            <span class="chart-legend-rate mr-1"></span>
            Estimated daily rate
            <span class="chart-legend-square mr-1 ml-2"></span>
            Season
            <span
              class="chart-legend-square legend-off-season mr-1 ml-2"
            ></span>
            Off season
          </div>
          <vue-frappe
            id="prices-chart"
            :labels="dates.labels"
            type="axis-mixed"
            :yMarkers="[
              {
                label: 'Maximum rate',
                value: hotel.maxRate,
                options: { labelPos: 'left' },
              },
            ]"
            :yRegions="
              hotel.minTripPrice < 100000 * hotel.tripLength
                ? [
                    {
                      label: 'Best rate',
                      start: (hotel.minTripPrice / hotel.tripLength) * 0.9,
                      end: (hotel.minTripPrice / hotel.tripLength) * 1.1,
                      options: { labelPos: 'right' },
                    },
                  ]
                : null
            "
            :axisOptions="{ xIsSeries: true, xAxisMode: 'tick' }"
            :lineOptions="{ hideDots: 1, heatline: 0 }"
            :barOptions="{
              spaceRatio: 0.0001,
            }"
            :height="300"
            :colors="['#7B898C', 'rgb(161,161,140,.1)']"
            :dataSets="this.dates.datasets"
            :tooltipOptions="{
              formatTooltipY: (d) =>
                d == 1
                  ? 'Unavailable'
                  : d == 0
                  ? 'No'
                  : d == Math.round(hotel.maxRate * 1.1)
                  ? 'Yes'
                  : '$' +
                    d?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
            }"
          ></vue-frappe>

          <h4>Trip price chart</h4>
          <b-form inline class="mb-4">
            Each point represents the cost of a trip consisting of
            {{ tripLength }} consecutive nights including taxes and fees.
          </b-form>

          <div>
            <span class="chart-legend-rate mr-1"></span>
            Estimated trip price
            <span class="chart-legend-square mr-1 ml-2"></span>
            Season
            <span
              class="chart-legend-square legend-off-season mr-1 ml-2"
            ></span>
            Off season
          </div>

          <vue-frappe
            id="trip-prices-chart"
            :labels="tripPrices.labels"
            type="axis-mixed"
            :yMarkers="
              hotel.minTripPrice < 100000 * hotel.tripLength
                ? [
                    {
                      label: 'Minimum trip price',
                      value: hotel.minBaseTripPrice,
                      options: { labelPos: 'left' },
                    },
                    {
                      label: 'Maximum trip price',
                      value: hotel.maxBaseTripPrice,
                      options: { labelPos: 'right' },
                    },
                  ]
                : null
            "
            :axisOptions="{ xIsSeries: true, xAxisMode: 'tick' }"
            :lineOptions="{ hideDots: 1, heatline: 0 }"
            :barOptions="{
              spaceRatio: 0.0001,
            }"
            :height="300"
            :colors="['#7B898C', 'rgb(161,161,140,.1)']"
            :dataSets="tripPrices.datasets"
            :tooltipOptions="{
              formatTooltipY: (d) =>
                d === 0
                  ? 'No'
                  : d === Math.round(hotel.maxBaseTripPrice * 1.1)
                  ? 'Yes'
                  : d == null
                  ? null
                  : '$' +
                    d.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
            }"
          />

          <div class="mb-4 text-uppercase text-muted">
            <small class="estimate">
              <font-awesome-icon :icon="['fas', 'info-circle']" />
              Prices last estimated
              {{ new Date(hotel.lastUpdated).toLocaleDateString("en-EN") }}
            </small>
          </div>

          <h4>Average weather</h4>
          <div>
            <span
              class="chart-legend-square legend-temperature mr-1 ml-2"
            ></span>
            Avg temperature (°C)
            <span
              class="chart-legend-square legend-precipitation mr-1 ml-2"
            ></span>
            Precipitation (ml)
          </div>
          <vue-frappe
            id="weather-chart"
            :labels="
              [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
              ].slice(
                tripPrices.firstMonth,
                tripPrices.firstMonth + tripPrices.months
              )
            "
            type="axis-mixed"
            :height="300"
            :colors="['#7B898C', '#E9E9E7']"
            :dataSets="[
              {
                name: 'Avg temperature (°C)',
                chartType: 'bar',
                values: hotel.temperature
                  .concat(hotel.temperature)
                  .slice(
                    tripPrices.firstMonth,
                    tripPrices.firstMonth + tripPrices.months
                  ),
              },
              {
                name: 'Precipitation (ml)',
                chartType: 'bar',
                values: hotel.precipitation
                  .concat(hotel.precipitation)
                  .slice(
                    tripPrices.firstMonth,
                    tripPrices.firstMonth + tripPrices.months
                  ),
              },
            ]"
            :tooltipOptions="{
              formatTooltipY: (d) => d,
            }"
          >
          </vue-frappe>
        </b-col>
      </b-row>

      <b-row>
        <b-col v-if="hotel.description" md="6" sm="12">
          <h4>About</h4>
          <p class="hotel-description">{{ hotel.description }}</p>
          <p>
            <a class="external-link" target="_blank" :href="hotel.website"
              >Visit hotel website to learn more</a
            >
          </p>
          <p>
            <a
              class="external-link"
              target="_blank"
              :href="
                'https://www.google.com/maps/place/?q=place_id:' +
                hotel.googlePlaceId
              "
              >Locate on Google Maps</a
            >
          </p>
          <p>
            <a
              class="external-link"
              :href="
                this.$router.resolve({
                  name: 'map',
                  query: {
                    region: countries[hotel.country].region,
                    lat: countries[hotel.country].lat,
                    lon: countries[hotel.country].lon,
                    zoom: countries[hotel.country].zoom,
                  },
                }).href
              "
            >
              See all hotels in {{ hotel.country }} on a map
            </a>
          </p>
          <p>
            <router-link
              class="external-link"
              :to="{
                name: 'map',
                query: { brand: hotel.brand },
              }"
            >
              See all {{ hotel.brand }} hotels on a map
            </router-link>
          </p>
          <h4 class="mt-5" v-if="hotel.nearestRelevantAirports.length > 0">
            Nearby airports
          </h4>
          <p v-for="airport in hotel.nearestRelevantAirports" :key="airport">
            <a
              class="external-link"
              target="_blank"
              :href="'https://www.google.com/search?q=' + airport + '+airport'"
            >
              {{ airports[airport].name }} ({{ airports[airport].city }},
              {{ airports[airport].country }})
            </a>
          </p>
        </b-col>
        <b-col>
          <h4>Location</h4>
          <Mapbox
            access-token="pk.eyJ1IjoiYXBhbmNpayIsImEiOiJjaW16Zm0xdGwwNGYxd2JsdTJhNWZmNTdnIn0.AauCo4ytDKQCYTgVvR65ig"
            :nav-control="{
              show: true,
            }"
            :map-options="{
              style: 'mapbox://styles/mapbox/light-v9',
              center: [hotel.longitude, hotel.latitude],
              zoom: 15,
            }"
            @map-load="saveMap"
          />
        </b-col>
      </b-row>
      <Footer></Footer>
    </b-container>
  </div>
</template>

<script>
import Vue from "vue";

import countriesData from "../../../published_regions.json";
import airportsData from "../../../published_airports.json";

import Menu from "../../components/Menu.vue";
import Footer from "../../components/Footer.vue";

//  mapbox-gl-vue.js
import Mapbox from "mapbox-gl-vue";
import "mapbox-gl/dist/mapbox-gl.css";

Vue.filter("capitalize", function (value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("breakdown", function (input) {
  return input.replace(/, /g, "\n");
});

function formatNumber(num) {
  return (num ? num : 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

Vue.filter("formatNumber", formatNumber);

export default {
  layout: "keepalive",
  data() {
    return {
      countries: countriesData,
      airports: airportsData,
    };
  },
  deactivated() {
    this.$destroy();
  },
  components: {
    Menu,
    Footer,
    Mapbox,
  },
  methods: {
    saveMap(map) {
      this.map = map;

      const hotel = this.hotel;

      map.addLayer({
        id: "points",
        type: "symbol",
        source: {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: [hotel.longitude, hotel.latitude],
                },
                properties: {
                  title: hotel.name,
                  icon: "marker",
                },
              },
            ],
          },
        },
        layout: {
          "icon-image": "{icon}-15",
          "text-field": "{title}",
          "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
          "text-offset": [0, 0.6],
          "text-anchor": "top",
        },
      });
    },
  },
  created() {
    //this.$store.dispatch("fetchHotels")
  },
  computed: {
    tripLength: {
      get() {
        return this.$store.state.tripLength;
      },
      set(value) {
        this.$store.commit("SET_TRIP_LENGTH", value);
      },
    },
    hotel() {
      return this.$store.getters.computed.allHotels.filter((item) => {
        return item.id === this.$route.params.id;
      })[0];
    },
    tripPrices() {
      const hotel = this.$store.getters.computed.allHotels.filter((item) => {
        return item.id === this.$route.params.id;
      })[0];

      var labels = [];
      var values = [];
      var offSeason = [];

      var firstDate;
      var lastDate;

      function differenceInMonths(d1, d2) {
        var months =
          (d2.getFullYear() - d1.getFullYear()) * 12 -
          d1.getMonth() +
          d2.getMonth() +
          1;

        return months <= 0 ? 0 : months;
      }

      Object.keys(hotel.baseTripPrices || {})
        .sort()
        .forEach((key) => {
          if (!firstDate)
            firstDate = new Date(new Date(key).getTime() + 12 * 60 * 60 * 1000); // timezones, sigh
          lastDate = new Date(key);

          labels.push(key);
          values.push(
            hotel.baseTripPrices[key] > 1000000
              ? null
              : hotel.baseTripPrices[key]
          );
          offSeason.push(
            hotel.weather[new Date(key).getMonth()] === 0
              ? Math.round(hotel.maxBaseTripPrice * 1.1)
              : 0
          );
        });

      return hotel.baseTripPrices
        ? {
            firstMonth: firstDate.getMonth(),
            lastDate: lastDate,
            months: differenceInMonths(firstDate, lastDate),
            labels: labels,
            datasets: [
              {
                chartType: "line",
                name: "Trip price",
                values: values,
              },
              {
                chartType: "bar",
                name: "Off season",
                values: offSeason,
              },
            ],
          }
        : { labels: [], datasets: [] };
    },
    dates() {
      const hotel = this.$store.getters.computed.allHotels.filter((item) => {
        return item.id === this.$route.params.id;
      })[0];

      var labels = [];
      var values = [];
      var offSeason = [];

      Object.keys(hotel.pricesUsd || {})
        .sort()
        .forEach((key) => {
          labels.push(key);
          values.push(hotel.pricesUsd[key] ? hotel.pricesUsd[key] : 1);
          offSeason.push(
            hotel.weather[new Date(key).getMonth()] === 0
              ? Math.round(hotel.maxRate * 1.1)
              : 0
          );
        });

      return {
        labels: labels,
        datasets: [
          {
            chartType: "line",
            name: "Estimated rate",
            values: values,
          },
          {
            chartType: "bar",
            name: "Off season",
            values: offSeason,
          },
        ],
      };
    },
  },
  mounted() {
    document.title = `${this.hotel.brand} ${this.hotel.name} | HotelMap`;
  },
  head() {
    return {
      meta: [
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1, shrink-to-fit=no",
        },
        // hid is used as unique identifier. Do not use `vmid` for it as it will not work
        {
          hid: "description",
          name: "description",
          content: this.hotel.description,
        },
      ],
    };
  },
};
</script>
