<template>
  <div class="hotel-detail">
    <Menu></Menu>
    <b-container>
      <b-row>
        <b-col>
          <h1 class="mt-4">Hotel brands</h1>
          <p v-for="brand in brands" :key="brand.brand">
            <router-link class="external-link" :to="brand.href"
              >{{ brand.brand }} map</router-link
            >
          </p>
          <h1 class="mt-4">Hotels</h1>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Brand</th>
                <th scope="col">Name</th>
                <th scope="col">Min. rate</th>
                <th scope="col">Avg. rate</th>
                <th scope="col">Max. rate</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="hotel in hotels" :key="hotel.id">
                <td>{{ hotel.brand }}</td>
                <td>{{ hotel.name }}</td>
                <td>${{ hotel.minRate | formatNumber }}</td>
                <td>${{ hotel.averageDailyRate | formatNumber }}</td>
                <td>${{ hotel.maxRate | formatNumber }}</td>
                <td>
                  <router-link
                    class="external-link"
                    :to="{
                      name: 'hotels-id',
                      params: { id: hotel.id },
                    }"
                  >
                    Open
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
      <Footer></Footer>
    </b-container>
  </div>
</template>

<script>
import Vue from "vue";

import airportsData from "../../data/airports.json";

import Menu from "../components/Menu.vue";
import Footer from "../components/Footer.vue";

function formatNumber(num) {
  return Math.round(num)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

Vue.filter("formatNumber", formatNumber);

export default {
  layout: "keepalive",
  data() {
    return {
      airports: airportsData,
    };
  },
  deactivated() {
    this.$destroy();
  },
  components: {
    Menu,
    Footer,
  },
  computed: {
    hotels() {
      const { allHotels } = this.$store.getters.computed;

      allHotels.sort((a, b) =>
        (a.brand + a.name).localeCompare(b.brand + b.name)
      );

      return allHotels;
    },
    brands() {
      const { brands } = this.$store.getters.computed;

      return Object.keys(brands)
        .sort()
        .map((brand) => {
          return {
            brand: brand,
            href: this.$router.resolve({
              name: "map",
              query: { brand: brand },
            }).href,
          };
        });
    },
  },
  mounted() {
    document.title = `Hotel Directory – HotelMap`;
  },
  head() {
    return {
      meta: [
        // hid is used as unique identifier. Do not use `vmid` for it as it will not work
        {
          hid: "description",
          name: "description",
          content: "All hotels on HotelMap",
        },
      ],
    };
  },
};
</script>
