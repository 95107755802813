import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import { BootstrapVue } from "bootstrap-vue";

// Import Bootstrap and BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import "./assets/main.scss";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);

// Add Font Awesome Icons
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faMapMarker,
  faMobileAlt,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
library.add(faMapMarker, faMobileAlt, faInfoCircle);

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
Vue.component("font-awesome-icon", FontAwesomeIcon);

import Chart from "vue2-frappe";

Vue.use(Chart);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
