<template>
  <div class="hotel-detail">
    <Menu></Menu>
    <b-container>
      <b-row>
        <b-col>
          <h1 class="mt-4">PRIVACY POLICY</h1>

          <p>Effective date: 06/21/2020</p>

          <h2>0. Introduction</h2>

          <p>
            Welcome to HotelMap, operated by pancik.com ( “we”, “our”, “us”)
          </p>

          <p>
            We operate
            <a class="text-muted" to="/">https://hotelmap.pancik.com/</a
            >(hereinafter referred to as “Service”).
          </p>

          <p>
            Our Privacy Policy governs your visit to
            <a class="text-muted" to="/">https://hotelmap.pancik.com/</a>, and
            explains how we collect, safeguard and disclose information that
            results from your use of our Service.
          </p>

          <p>
            We use your data to provide and improve Service. By using Service,
            you agree to the collection and use of information in accordance
            with this policy. Unless otherwise defined in this Privacy Policy,
            the terms used in this Privacy Policy have the same meanings as in
            our Terms and Conditions.
          </p>

          <p>
            Our Terms and Conditions (“Terms”) govern all use of our Service and
            together with the Privacy Policy constitutes your agreement with us
            (“agreement”).
          </p>

          <h2>1. Definitions</h2>

          <p>
            SERVICE means the
            <a class="text-muted" to="/">https://hotelmap.pancik.com/</a>website
            operated by us.
          </p>

          <p>
            PERSONAL DATA means data about a living individual who can be
            identified from those data (or from those and other information
            either in our possession or likely to come into our possession).
          </p>

          <p>
            USAGE DATA is data collected automatically either generated by the
            use of Service or from Service infrastructure itself (for example,
            the duration of a page visit).
          </p>

          <p>
            COOKIES are small files stored on your device (computer or mobile
            device).
          </p>

          <p>
            DATA CONTROLLER means a natural or legal person who (either alone or
            jointly or in common with other persons) determines the purposes for
            which and the manner in which any personal data are, or are to be,
            processed. For the purpose of this Privacy Policy, we are a Data
            Controller of your data.
          </p>

          <p>
            DATA PROCESSORS (OR SERVICE PROVIDERS) means any natural or legal
            person who processes the data on behalf of the Data Controller. We
            may use the services of various Service Providers in order to
            process your data more effectively.
          </p>

          <p>
            DATA SUBJECT is any living individual who is the subject of Personal
            Data.
          </p>

          <p>
            THE USER is the individual using our Service. The User corresponds
            to the Data Subject, who is the subject of Personal Data.
          </p>

          <h2>2. Information Collection and Use</h2>

          <p>
            We collect several different types of information for various
            purposes to provide and improve our Service to you.
          </p>

          <h2>3. Types of Data Collected</h2>

          <p>
            While using our Service, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you (“Personal Data”). Personally identifiable information
            may include, but is not limited to:
          </p>

          <h3>3.1 Usage Data</h3>

          <p>
            We may also collect information that your browser sends whenever you
            visit our Service or when you access Service by or through a mobile
            device (“Usage Data”).
          </p>

          <p>
            This Usage Data may include information such as your computer's
            Internet Protocol address (e.g. IP address), browser type, browser
            version, the pages of our Service that you visit, the time and date
            of your visit, the time spent on those pages, unique device
            identifiers and other diagnostic data.
          </p>

          <p>
            When you access Service with a mobile device, this Usage Data may
            include information such as the type of mobile device you use, your
            mobile device unique ID, the IP address of your mobile device, your
            mobile operating system, the type of mobile Internet browser you
            use, unique device identifiers and other diagnostic data.
          </p>

          <h3>3.2 Location Data</h3>

          <p>
            We may use and store information about your location if you give us
            permission to do so (“Location Data”). We use this data to provide
            features of our Service, to improve and customize our Service.
          </p>

          <p>
            You can enable or disable location services when you use our Service
            at any time by way of your device settings.
          </p>

          <h3>3.3 Tracking Cookies Data</h3>

          <p>
            We use cookies and similar tracking technologies to track the
            activity on our Service and we hold certain information.
          </p>

          <p>
            Cookies are files with a small amount of data which may include an
            anonymous unique identifier. Cookies are sent to your browser from a
            website and stored on your device. Other tracking technologies are
            also used such as beacons, tags and scripts to collect and track
            information and to improve and analyze our Service.
          </p>

          <p>
            You can instruct your browser to refuse all cookies or to indicate
            when a cookie is being sent. However, if you do not accept cookies,
            you may not be able to use some portions of our Service.
          </p>

          <p>Examples of Cookies we use:</p>

          <p>
            3.3.1. Session Cookies: We use Session Cookies to operate our
            Service.
          </p>

          <p>
            3.3.2. Preference Cookies: We use Preference Cookies to remember
            your preferences and various settings.
          </p>

          <p>
            3.3.3. Security Cookies: We use Security Cookies for security
            purposes.
          </p>

          <p>
            3.3.4. Advertising Cookies: Advertising Cookies are used to serve
            you with advertisements that may be relevant to you and your
            interests.
          </p>

          <h2>4. Use of Data</h2>

          <p>HotelMap uses the collected data for various purposes:</p>

          <p>4.1 to provide and maintain our Service;</p>

          <p>4.2 to notify you about changes to our Service;</p>

          <p>
            4.3 to allow you to participate in interactive features of our
            Service when you choose to do so;
          </p>

          <p>4.4 to provide customer support;</p>

          <p>
            4.5 to gather analysis or valuable information so that we can
            improve our Service;
          </p>

          <p>4.6 to monitor the usage of our Service;</p>

          <p>4.7 to detect, prevent and address technical issues;</p>

          <p>4.8 to fulfill any other purpose for which you provide it;</p>

          <p>
            4.9 to carry out our obligations and enforce our rights arising from
            any contracts entered into between you and us, including for billing
            and collection;
          </p>

          <p>
            4.10 to provide you with notices about your account and/or
            subscription, including expiration and renewal notices,
            email-instructions, etc.;
          </p>

          <p>
            4.11 to provide you with news, special offers and general
            information about other goods, services and events which we offer
            that are similar to those that you have already purchased or
            enquired about unless you have opted not to receive such
            information;
          </p>

          <p>
            4.12 in any other way we may describe when you provide the
            information;
          </p>

          <p>4.13 for any other purpose with your consent.</p>

          <h2>5. Retention of Data</h2>

          <p>
            We will retain your Personal Data only for as long as is necessary
            for the purposes set out in this Privacy Policy. We will retain and
            use your Personal Data to the extent necessary to comply with our
            legal obligations (for example, if we are required to retain your
            data to comply with applicable laws), resolve disputes, and enforce
            our legal agreements and policies.
          </p>

          <p>
            We will also retain Usage Data for internal analysis purposes. Usage
            Data is generally retained for a shorter period, except when this
            data is used to strengthen the security or to improve the
            functionality of our Service, or we are legally obligated to retain
            this data for longer time periods.
          </p>

          <h2>6. Transfer of Data</h2>

          <p>
            Your information, including Personal Data, may be transferred to –
            and maintained on – computers located outside of your state,
            province, country or other governmental jurisdiction where the data
            protection laws may differ from those of your jurisdiction.
          </p>

          <p>
            If you are located outside United States and choose to provide
            information to us, please note that we transfer the data, including
            Personal Data, to United States and process it there.
          </p>

          <p>
            Your consent to this Privacy Policy followed by your submission of
            such information represents your agreement to that transfer.
          </p>

          <p>
            HotelMap will take all the steps reasonably necessary to ensure that
            your data is treated securely and in accordance with this Privacy
            Policy and no transfer of your Personal Data will take place to an
            organisation or a country unless there are adequate controls in
            place including the security of your data and other personal
            information.
          </p>

          <h2>7. Disclosure of Data</h2>

          <p>
            We may disclose personal information that we collect, or you
            provide:
          </p>

          <p>
            7.1 Under certain circumstances, we may be required to disclose your
            Personal Data if required to do so by law or in response to valid
            requests by public authorities.
          </p>

          <p>
            7.2 If we or our subsidiaries are involved in a merger, acquisition
            or asset sale, your Personal Data may be transferred.
          </p>

          <p>7.3 to our subsidiaries and affiliates;</p>

          <p>7.4 to fulfill the purpose for which you provide it;</p>

          <p>
            7.5 for any other purpose disclosed by us when you provide the
            information;
          </p>

          <p>7.6 with your consent in any other cases;</p>

          <h2>8. Security of Data</h2>

          <p>
            The security of your data is important to us but remember that no
            method of transmission over the Internet or method of electronic
            storage is 100% secure. While we strive to use commercially
            acceptable means to protect your Personal Data, we cannot guarantee
            its absolute security.
          </p>

          <h2>9. Service Providers</h2>

          <p>
            We may employ third party companies and individuals to facilitate
            our Service (“Service Providers”), provide Service on our behalf,
            perform Service-related services or assist us in analysing how our
            Service is used.
          </p>

          <p>
            These third parties have access to your Personal Data only to
            perform these tasks on our behalf and are obligated not to disclose
            or use it for any other purpose.
          </p>

          <h2>10. Analytics</h2>

          <p>
            We may use third-party Service Providers to monitor and analyze the
            use of our Service.
          </p>

          <p>Google Analytics</p>

          <p>
            Google Analytics is a web analytics service offered by Google that
            tracks and reports website traffic. Google uses the data collected
            to track and monitor the use of our Service. This data is shared
            with other Google services. Google may use the collected data to
            contextualise and personalise the ads of its own advertising
            network.
          </p>

          <p>
            For more information on the privacy practices of Google, please
            visit the Google Privacy Terms web page:
            https://policies.google.com/privacy?hl=en
          </p>

          <p>
            We also encourage you to review the Google's policy for safeguarding
            your data: https://support.google.com/analytics/answer/6004245.
          </p>

          <h2>11. Links to Other Sites</h2>

          <p>
            Our Service may contain links to other sites that are not operated
            by us. If you click a third party link, you will be directed to that
            third party's site. We strongly advise you to review the Privacy
            Policy of every site you visit.
          </p>

          <p>
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </p>

          <h2>12. Children's Privacy</h2>

          <p>
            Our Services are not intended for use by children under the age of
            18 (“Child” or “Children”).
          </p>

          <p>
            We do not knowingly collect personally identifiable information from
            Children under 18. If you become aware that a Child has provided us
            with Personal Data, please contact us. If we become aware that we
            have collected Personal Data from Children without verification of
            parental consent, we take steps to remove that information from our
            servers.
          </p>

          <h2>13. Changes to This Privacy Policy</h2>

          <p>
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
          </p>

          <p>
            We will let you know via email and/or a prominent notice on our
            Service, prior to the change becoming effective and update
            “effective date” at the top of this Privacy Policy.
          </p>

          <p>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>

          <h2>14. Contact Us</h2>

          <p>
            If you have any questions about this Privacy Policy, please
            <a class="text-muted" href="https://www.linkedin.com/in/apancik/"
              >contact us</a
            >
          </p>
        </b-col>
      </b-row>
      <Footer></Footer>
    </b-container>
  </div>
</template>

<script lang="ts">
import Menu from "../components/Menu.vue";
import Footer from "../components/Footer.vue";

export default {
  layout: "default",
  components: {
    Menu,
    Footer,
  },
  mounted() {
    window.scrollTo(0, 0);
    document.title = `Privacy Policy – HotelMap`;
  },
  head() {
    return {
      meta: [
        // hid is used as unique identifier. Do not use `vmid` for it as it will not work
        {
          hid: "description",
          name: "description",
          content: "My custom description",
        },
      ],
    };
  },
};
</script>
