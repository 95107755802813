<template>
  <div class="hotel-detail">
    <Menu></Menu>
    <b-container>
      <b-row>
        <b-col>
          <h1 class="mt-4">TERMS OF SERVICE</h1>

          <p>Last updated: 06/21/2020</p>

          <h2>0. Introduction</h2>

          <p>
            Welcome to HotelMap, operated by pancik.com ( “we”, “our”, “us”)! As
            you have just clicked our Terms of Service, please pause, grab a cup
            of coffee and carefully read the following pages. It will take you
            approximately 20 minutes.
          </p>

          <p>
            These Terms of Service (“Terms”, “Terms of Service”) govern your use
            of our web pages located at
            <a class="text-muted" to="/">https://hotelmap.pancik.com/</a>.
            operated by pancik.com.
          </p>

          <p>
            Our Privacy Policy also governs your use of our Service and explains
            how we collect, safeguard and disclose information that results from
            your use of our web pages. Please read it here:
            <a class="text-muted" to="/privacy"
              >https://hotelmap.pancik.com/privacy</a
            >.
          </p>

          <p>
            Your agreement with us includes these Terms and our Privacy Policy
            (“Agreements”). You acknowledge that you have read and understood
            Agreements, and agree to be bound of them.
          </p>

          <p>
            If you do not agree with (or cannot comply with) Agreements, then
            you may not use the Service, but please let us know by
            <a class="text-muted" href="https://www.linkedin.com/in/apancik/"
              >contacting us</a
            >
            so we can try to find a solution. These Terms apply to all visitors,
            users and others who wish to access or use Service.
          </p>

          <p>
            This site is operated by a natural person in the course of a purely
            personal activity.
          </p>

          <p>Thank you for being responsible.</p>

          <h2>1. Prohibited Uses</h2>

          <p>
            You may use Service only for lawful purposes and in accordance with
            Terms. You agree not to use Service:
          </p>

          <p>
            1.1 In any way that violates any applicable national or
            international law or regulation.
          </p>

          <p>
            1.2 For the purpose of exploiting, harming, or attempting to exploit
            or harm minors in any way by exposing them to inappropriate content
            or otherwise.
          </p>

          <p>
            1.3 To transmit, or procure the sending of, any advertising or
            promotional material, including any “junk mail”, “chain letter,”
            “spam,” or any other similar solicitation.
          </p>

          <p>
            1.4 In any way that infringes upon the rights of others, or in any
            way is illegal, threatening, fraudulent, or harmful, or in
            connection with any unlawful, illegal, fraudulent, or harmful
            purpose or activity.
          </p>

          <p>
            1.5 To engage in any other conduct that restricts or inhibits
            anyone’s use or enjoyment of Service, or which, as determined by us,
            may harm or offend us or users of Service or expose them to
            liability.
          </p>

          <p>Additionally, you agree not to:</p>

          <p>
            1.6 Use Service in any manner that could disable, overburden,
            damage, or impair Service or interfere with any other party’s use of
            Service, including their ability to engage in real time activities
            through Service.
          </p>

          <p>
            1.7 Use any robot, spider, or other automatic device, process, or
            means to access Service for any purpose, including monitoring or
            copying any of the material on Service.
          </p>

          <p>
            1.8 Use any manual process to monitor or copy any of the material on
            Service or for any other unauthorized purpose without our prior
            written consent.
          </p>

          <p>
            1.9 Use any device, software, or routine that interferes with the
            proper working of Service.
          </p>

          <p>
            1.10 Introduce any viruses, trojan horses, worms, logic bombs, or
            other material which is malicious or technologically harmful.
          </p>

          <p>
            1.11 Attempt to gain unauthorized access to, interfere with, damage,
            or disrupt any parts of Service, the server on which Service is
            stored, or any server, computer, or database connected to Service.
          </p>

          <p>
            1.12 Attack Service via a denial-of-service attack or a distributed
            denial-of-service attack.
          </p>

          <p>
            1.13 Otherwise attempt to interfere with the proper working of
            Service.
          </p>

          <h2>2. Analytics</h2>

          <p>
            We may use third-party Service Providers to monitor and analyze the
            use of our Service.
          </p>

          <p>Google Analytics</p>

          <p>
            Google Analytics is a web analytics service offered by Google that
            tracks and reports website traffic. Google uses the data collected
            to track and monitor the use of our Service. This data is shared
            with other Google services. Google may use the collected data to
            contextualise and personalise the ads of its own advertising
            network.
          </p>

          <p>
            For more information on the privacy practices of Google, please
            visit the Google Privacy Terms web page:
          </p>

          <p>
            We also encourage you to review the Google's policy for safeguarding
            your data: .
          </p>

          <h2>3. No Use By Minors</h2>

          <p>
            Service is intended only for access and use by individuals at least
            eighteen (18) years old. By accessing or using the Service, you
            warrant and represent that you are at least eighteen (18) years of
            age and with the full authority, right, and capacity to enter into
            this agreement and abide by all of the terms and conditions of
            Terms. If you are not at least eighteen (18) years old, you are
            prohibited from both the access and usage of Service.
          </p>

          <h2>4. Intellectual Property</h2>

          <p>
            All product names, logos, and brands are property of their
            respective owners. All company, product and service names used in
            this website are for identification purposes only. Use of these
            names, logos, and brands does not imply endorsement.
          </p>

          <p>
            Service and its original content (excluding third-party Content
            provided), features and functionality are and will remain the
            exclusive property of HotelMap and its licensors. Service is
            protected by copyright, trademark, and other laws of the United
            States and foreign countries. Our trademarks and trade dress may not
            be used in connection with any product or service without the prior
            written consent of HotelMap.
          </p>

          <h2>5. Error Reporting and Feedback</h2>

          <p>
            You may
            <a class="text-muted" href="https://www.linkedin.com/in/apancik/"
              >contact us</a
            >
            to provide us with information and feedback concerning errors,
            suggestions for improvements, ideas, problems, complaints, and other
            matters related to our Service (“Feedback”). You acknowledge and
            agree that: (i) you shall not retain, acquire or assert any
            intellectual property right or other right, title or interest in or
            to the Feedback; (ii) We may have development ideas similar to the
            Feedback; (iii) Feedback does not contain confidential information
            or proprietary information from you or any third party; and (iv) We
            are not under any obligation of confidentiality with respect to the
            Feedback. In the event the transfer of the ownership to the Feedback
            is not possible due to applicable mandatory laws, you grant us and
            our affiliates an exclusive, transferable, irrevocable,
            free-of-charge, sub-licensable, unlimited and perpetual right to use
            (including copy, modify, create derivative works, publish,
            distribute and commercialize) Feedback in any manner and for any
            purpose.
          </p>

          <h2>6. Links To Other Web Sites</h2>

          <p>
            Our Service may contain links to third party web sites or services
            that are not owned or controlled by HotelMap.
          </p>

          <p>
            HotelMap has no control over, and assumes no responsibility for the
            content, privacy policies, or practices of any third party web sites
            or services. We do not warrant the offerings of any of these
            entities/individuals or their websites.
          </p>

          <p>
            YOU ACKNOWLEDGE AND AGREE THAT HOTELMAP SHALL NOT BE RESPONSIBLE OR
            LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR
            ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE ON
            ANY SUCH CONTENT, GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH
            THIRD PARTY WEB SITES OR SERVICES.
          </p>

          <p>
            WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY
            POLICIES OF ANY THIRD PARTY WEB SITES OR SERVICES THAT YOU VISIT.
          </p>

          <h2>7. Disclaimer Of Warranty</h2>

          <p>
            THESE SERVICES ARE PROVIDED BY US ON AN “AS IS” AND “AS AVAILABLE”
            BASIS. WE MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS
            OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES, OR THE
            INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU EXPRESSLY
            AGREE THAT YOUR USE OF THESE SERVICES, THEIR CONTENT, AND ANY
            SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.
          </p>

          <p>
            NEITHER WE NOR ANY PERSON ASSOCIATED WITH US MAKES ANY WARRANTY OR
            REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY,
            RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES.
            WITHOUT LIMITING THE FOREGOING, NEITHER US NOR ANYONE ASSOCIATED
            WITH US REPRESENTS OR WARRANTS THAT THE SERVICES, THEIR CONTENT, OR
            ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE
            ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL
            BE CORRECTED, THAT THE SERVICES OR THE SERVER THAT MAKES IT
            AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT
            THE SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES
            WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
          </p>

          <p>
            WE HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
            IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY
            WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR
            PARTICULAR PURPOSE.
          </p>

          <p>
            THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE
            EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
          </p>

          <h2>8. Limitation Of Liability</h2>

          <p>
            EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS,
            DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT,
            PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT
            ARISES (INCLUDING ATTORNEYS' FEES AND ALL RELATED COSTS AND EXPENSES
            OF LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY,
            WHETHER OR NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN
            AN ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR
            ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING
            WITHOUT LIMITATION ANY CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE,
            ARISING FROM THIS AGREEMENT AND ANY VIOLATION BY YOU OF ANY FEDERAL,
            STATE, OR LOCAL LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF WE
            HAVE BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
            EXCEPT AS PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE OUR
            PART, IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS AND/OR
            SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE CONSEQUENTIAL OR
            PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE EXCLUSION OR
            LIMITATION OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE
            PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
          </p>

          <h2>9. Termination</h2>

          <p>
            We may terminate or bar access to Service immediately, without prior
            notice or liability, under our sole discretion, for any reason
            whatsoever and without limitation, including but not limited to a
            breach of Terms.
          </p>

          <p>
            If you wish to terminate your account, you may simply discontinue
            using Service.
          </p>

          <p>
            All provisions of Terms which by their nature should survive
            termination shall survive termination, including, without
            limitation, ownership provisions, warranty disclaimers, indemnity
            and limitations of liability.
          </p>

          <h2>10. Governing Law</h2>

          <p>
            These Terms shall be governed and construed in accordance with the
            laws of State of New York without regard to its conflict of law
            provisions.
          </p>

          <p>
            Our failure to enforce any right or provision of these Terms will
            not be considered a waiver of those rights. If any provision of
            these Terms is held to be invalid or unenforceable by a court, the
            remaining provisions of these Terms will remain in effect. These
            Terms constitute the entire agreement between us regarding our
            Service and supersede and replace any prior agreements we might have
            had between us regarding Service.
          </p>

          <h2>11. Changes To Service</h2>

          <p>
            We reserve the right to withdraw or amend our Service, and any
            service or material we provide via Service, in our sole discretion
            without notice. We will not be liable if for any reason all or any
            part of Service is unavailable at any time or for any period. From
            time to time, we may restrict access to some parts of Service, or
            the entire Service, to users, including registered users.
          </p>

          <h2>12. Amendments To Terms</h2>

          <p>
            We may amend Terms at any time by posting the amended terms on this
            site. It is your responsibility to review these Terms periodically.
          </p>

          <p>
            Your continued use of the Platform following the posting of revised
            Terms means that you accept and agree to the changes. You are
            expected to check this page frequently so you are aware of any
            changes, as they are binding on you.
          </p>

          <p>
            By continuing to access or use our Service after any revisions
            become effective, you agree to be bound by the revised terms. If you
            do not agree to the new terms, you are no longer authorized to use
            Service.
          </p>

          <h2>13. Waiver And Severability</h2>

          <p>
            No waiver by us of any term or condition set forth in Terms shall be
            deemed a further or continuing waiver of such term or condition or a
            waiver of any other term or condition, and any failure of us to
            assert a right or provision under Terms shall not constitute a
            waiver of such right or provision.
          </p>

          <p>
            If any provision of Terms is held by a court or other tribunal of
            competent jurisdiction to be invalid, illegal or unenforceable for
            any reason, such provision shall be eliminated or limited to the
            minimum extent such that the remaining provisions of Terms will
            continue in full force and effect.
          </p>

          <h2>14. Acknowledgement</h2>

          <p>
            BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE
            THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY
            THEM.
          </p>

          <h2>15. Contact Us</h2>

          <p>
            Please send your feedback, comments, requests for technical support
            <a class="text-muted" href="https://www.linkedin.com/in/apancik/"
              >here</a
            >
          </p>
        </b-col>
      </b-row>
      <Footer></Footer>
    </b-container>
  </div>
</template>

<script>
import Menu from "../components/Menu.vue";
import Footer from "../components/Footer.vue";

export default {
  layout: "default",
  components: {
    Menu,
    Footer,
  },
  mounted() {
    window.scrollTo(0, 0);
    document.title = `Terms of Service – HotelMap`;
  },
  head() {
    return {
      meta: [
        // hid is used as unique identifier. Do not use `vmid` for it as it will not work
        {
          hid: "description",
          name: "description",
          content: "My custom description",
        },
      ],
    };
  },
};
</script>
