<template>
  <div>
    <Menu></Menu>
    <b-container fluid>
      <b-row>
        <b-col sm="3">
          <div class="scrollable-container">
            <b-form-group>
              <b-form-input
                :value="tripLength + '-night trip ' + selectedMonthsString"
                readonly
              ></b-form-input>
            </b-form-group>

            <b-form-group>
              <b-button-group size="sm">
                <b-button
                  :pressed.sync="selectedLength[7]"
                  @click="tripLength = 7"
                  variant="outline-dark"
                  >1 week</b-button
                >
                <b-button
                  :pressed.sync="selectedLength[14]"
                  @click="tripLength = 14"
                  variant="outline-dark"
                  >2 weeks</b-button
                >
              </b-button-group>
            </b-form-group>

            <b-form-group>
              <b-button
                size="sm"
                :pressed.sync="selectedAllMonths"
                variant="outline-dark"
                >All</b-button
              >
              <b-button-group size="sm">
                <b-button
                  v-for="(btn, idx) in selectedMonths"
                  :key="idx"
                  :pressed.sync="btn.state"
                  variant="outline-dark"
                  >{{ btn.caption }}</b-button
                >
              </b-button-group>
            </b-form-group>

            <b-form-group>
              <b-form-checkbox
                id="checkbox-1"
                name="checkbox-1"
                v-model="searchOffSeason"
                >Include off season</b-form-checkbox
              >
            </b-form-group>

            <hr />

            <b-form-group label="Trip budget">
              <b-input-group prepend="$">
                <b-form-input
                  id="max-price"
                  v-model.number="maxPrice"
                  placeholder="Unlimited"
                ></b-form-input>
              </b-input-group>
            </b-form-group>

            <hr />

            <b-form-group label="Categories">
              <b-form-checkbox-group
                id="checkbox-group-2"
                v-model="selectedCategories"
                :options="categories"
                name="flavour-1"
              ></b-form-checkbox-group>
            </b-form-group>

            <hr />

            <b-form-group label="Brands">
              <b-form-checkbox-group
                id="checkbox-group-1"
                v-model="selectedBrands"
                :options="brands"
                name="flavour-1"
              ></b-form-checkbox-group>
            </b-form-group>

            <hr />

            <b-form-group label="Currency">
              <b-form-select
                v-model="currency"
                :options="currencies"
              ></b-form-select>
            </b-form-group>

            <hr />
            <!--
						<b-form-group label="Quick Filter">
							<b-form-input v-model="searchExpression" placeholder="Maldives, London, Four Seasons..."></b-form-input>
						</b-form-group>

						<hr />
						-->
            <b-form-group label="Minimum rating">
              <b-form-rating
                v-model="minRating"
                show-clear
                inline
              ></b-form-rating>
            </b-form-group>
          </div>
        </b-col>
        <b-col>
          <b-tabs small pills align="right" end>
            <b-tab
              :title="'Cheapest ' + tripLength + '-night trips on a map'"
              active
            >
              <Mapbox
                access-token="pk.eyJ1IjoiYXBhbmNpayIsImEiOiJjaW16Zm0xdGwwNGYxd2JsdTJhNWZmNTdnIn0.AauCo4ytDKQCYTgVvR65ig"
                :nav-control="{
                  show: true,
                }"
                :map-options="{
                  style: 'mapbox://styles/mapbox/light-v9',
                  center: [
                    this.$route.query.lon || 0,
                    this.$route.query.lat || 0,
                  ],
                  zoom: this.$route.query.zoom || 1,
                }"
                @map-load="saveMap"
                @map-zoomend="zoomendMap"
              />
            </b-tab>
            <b-tab :title="'as a list (' + filteredHotels.length + ' results)'">
              <div class="scrollable-list">
                <div v-if="filteredHotels.length">
                  <b-card
                    v-for="hotel in filteredHotels"
                    :key="hotel.id"
                    :title="hotel.brand + ' ' + hotel.name"
                    tag="article"
                    class="mb-2"
                  >
                    <p>{{ hotel.address }}</p>

                    <p>
                      <b-badge variant="dark">
                        {{ hotel.rating }}
                      </b-badge>

                      ({{ hotel.userRatingsTotal }} reviews)
                    </p>

                    <p v-if="hotel.minTripPrice < 100000">
                      {{ tripLength }}-day trip available from ${{
                        hotel.minTripPrice | formatNumber
                      }}
                    </p>
                    <router-link
                      class="external-link"
                      :to="{
                        name: 'hotels-id',
                        params: { id: hotel.id },
                      }"
                      >See details</router-link
                    >
                  </b-card>
                </div>
                <div v-else>No matches</div>
              </div>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Vue from "vue";

import _ from "lodash";

//  mapbox-gl-vue.js
const mapboxgl = require("mapbox-gl");
import Mapbox from "mapbox-gl-vue";
import "mapbox-gl/dist/mapbox-gl.css";

// eslint-disable-next-line vue/multi-word-component-names
// Vue.component("mapbox", Mapbox);

import Menu from "../components/Menu.vue";

import fx from "../../data/utilities/fx.json";

function convertFx(price, currencyFrom, currencyTo) {
  //	console.log(new Date(), "\x1b[4m", "convertToDollars(price, currency)", "\x1b[0m", price, currency)

  return Math.round((price * fx[currencyFrom]) / fx[currencyTo]);
}

function formatCurrency(num, currency) {
  return (
    (currency == "USD" ? "$" : "") +
    formatNumber(num) +
    (currency == "USD" ? "" : " " + currency)
  );
}

function displayPrice(price, currency) {
  return formatCurrency(convertFx(price, "USD", currency), currency);
}

function formatNumber(num) {
  return Math.round(num)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

Vue.filter("formatNumber", formatNumber);

function capitalize(input) {
  return (input || "")
    .split(" ")
    .map((word) => {
      return word ? word[0].toUpperCase() + word.substring(1) : word;
    })
    .join(" ");
}

Vue.filter("capitalize", capitalize);

function extractDimensions(el) {
  const rect = el.getBoundingClientRect();
  const width = el.offsetWidth;
  const height = el.offsetHeight;
  const style = getComputedStyle(el);

  return {
    outerHeight:
      height + parseInt(style.marginTop) + parseInt(style.marginBottom),
    outerWidth:
      width + parseInt(style.marginLeft) + parseInt(style.marginRight),
    topOffset: rect.top + document.body.scrollTop,
    leftOffset: rect.left + document.body.scrollLeft,
  };
}

function collision($div1, $div2) {
  const overlap = 0.8;

  var dimensions1 = extractDimensions($div1);
  var dimensions2 = extractDimensions($div2);

  var topOffset1 = dimensions1.topOffset;
  var bottomOffset1 = topOffset1 + dimensions1.outerHeight * overlap;

  var leftOffset1 = dimensions1.leftOffset;
  var rightOffset1 = leftOffset1 + dimensions1.outerWidth * overlap;

  var topOffset2 = dimensions2.topOffset;
  var bottomOffset2 = topOffset2 + dimensions2.outerHeight * overlap;

  var leftOffset2 = dimensions2.leftOffset;
  var rightOffset2 = leftOffset2 + dimensions2.outerWidth * overlap;

  if (
    bottomOffset1 <= topOffset2 ||
    topOffset1 >= bottomOffset2 ||
    rightOffset1 <= leftOffset2 ||
    leftOffset1 >= rightOffset2
  ) {
    return false;
  } else {
    return true;
  }
}

export default {
  activated() {
    this.currency = this.$route.query.currency || this.currency;
    this.selectedBrands = this.$route.query.brand
      ? [this.$route.query.brand]
      : this.selectedBrands;
    this.zoomendMap(this.map);
  },
  mounted() {
    document.title = (this.selectedBrands.length == 1 || this.$route.query.region
          ? (this.selectedBrands.length == 1
              ? this.selectedBrands[0] + " "
              : "") +
            "Hotel Map" +
            (this.$route.query.region
              ? " of " + this.$route.query.region
              : "") +
            " – "
          : "") + "HotelMap";
  },
  head() {
    return {

      meta: [
        //{ name: "viewport", content: "width=1024" },
        // hid is used as unique identifier. Do not use `vmid` for it as it will not work
        {
          hid: "description",
          name: "description",
          content: "Cheapest hotel rates for a flexible traveler on a map",
        },
      ],
    };
  },
  data() {
    return {
      currencies: (() => {
        return Object.keys(fx).map((currency) => {
          return {
            value: currency,
            text: currency,
          };
        });
      })(),
      selectedMonths: (() => {
        const formatter = new Intl.DateTimeFormat("en", {
          month: "short",
        });
        var months = [];
        var date = new Date();

        for (var i = 0; i < 6; i++) {
          months.push({
            caption: formatter.format(date),
            state: true,
            month: date.getMonth(),
          });
          date.setMonth(date.getMonth() + 1);
        }

        return months;
      })(),
      brands: [],
      categories: [
        {
          text: "Ultraluxury",
          value: "Ultraluxury",
        },
        {
          text: "Luxury",
          value: "Luxury",
        },
        {
          text: "Premium",
          value: "Premium",
        },
      ],
      selectedCategories: ["Ultraluxury", "Luxury"],
      selectedBrands: [
        "Four Seasons",
        "Aman",
        "The Ritz-Carlton",
        "EDITION Hotels",
        "The Ritz-Carlton Reserve",
        "The Luxury Collection",
        "St. Regis",
        "W Hotels",
        "Autograph Collection",
        "Design Hotels™",
        "Park Hyatt",
      ],
      allBrands: [],
      selectedLength: {
        7: true,
        14: false,
      },

      searchExpression: " ",
      maxPrice: null,
      maxTripPrice: 0,
      minRating: 0,
      currency: "USD",
    };
  },
  mixins: [],
  created() {
    // Initialisation & data download
  },
  computed: {
    filteredHotels() {
      const { allHotels, maxTripPrice, brands } = this.$store.getters.computed;

      // eslint-disable-next-line
      var cur = this.currency; // makes sure this is rerendered when currency changes

      if (!this.allBrands.length) {
        this.allBrands = Object.keys(brands);
        if (!this.selectedBrands.length) this.selectedBrands = this.allBrands;
      }

      this.brands = Object.keys(brands)
        .map((brand) => {
          return {
            text: `${brand} (${brands[brand]})`,
            quantity: brands[brand],
            value: brand,
          };
        })
        .sort((a, b) => {
          return Math.round(b.quantity) - Math.round(a.quantity);
        });

      this.maxTripPrice = maxTripPrice;

      const matcher = new RegExp(this.searchExpression, "i");

      const hotels = allHotels
        .filter(
          (hotel) =>
            matcher.test(
              [
                hotel.name,
                hotel.address,
                hotel.airport,
                hotel.brand,
                hotel.country,
                hotel.location,
                hotel.category,
              ].join(" ")
            ) &&
            hotel.rating >= this.minRating &&
            (this.maxPrice ? hotel.minTripPrice <= this.maxPrice : true) &&
            this.selectedBrands.includes(hotel.brand) &&
            this.selectedCategories.includes(hotel.category) &&
            hotel.minTripPrice < 100000
        )
        .slice(-800);

      this.addMarkers(hotels);

      return hotels;
    },
    selectedMonthsString() {
      var selectedMonthsStrings = [];

      this.selectedMonths.forEach((month) => {
        if (month.state) selectedMonthsStrings.push(month.caption);
      });

      if (selectedMonthsStrings.length >= 6) return "in the next 6 months";
      else if (selectedMonthsStrings.length == 0) return "never";
      else if (selectedMonthsStrings.length < 5)
        return "in " + selectedMonthsStrings.join(", ");
      return "during selected months";
    },
    selectedAllMonths: {
      get() {
        this.$store.commit(
          "SET_MONTHS",
          this.selectedMonths.map((month) => (month.state ? month.month : null))
        );

        return this.selectedMonths.reduce((acc, n) => {
          return acc && n.state;
        }, true);
      },
      set(value) {
        this.selectedMonths.forEach((month) => (month.state = value));
      },
    },
    searchOffSeason: {
      get() {
        return !this.$store.state.seasonOnly;
      },
      set(value) {
        this.$store.commit("SET_SEASON_ONLY", !value);
      },
    },
    tripLength: {
      get() {
        return this.$store.state.tripLength;
      },
      set(value) {
        this.$store.commit("SET_TRIP_LENGTH", value);

        for (let x in this.selectedLength) {
          this.selectedLength[x] = false;
        }

        this.selectedLength[value] = true;

        this.maxPrice = null;
      },
    },
  },
  components: {
    Menu,
    Mapbox,
  },
  asyncData() {},
  methods: {
    addMarkers: _.debounce(function (hotels) {
      if (this.map) {
        this.popupsCache = this.popupsCache || {};
        this.popups = [];
        var toKeep = [];

        // CLEAN MARKERS
        this.markers = this.markers || {};
        Object.values(this.markers).forEach((marker) => {
          marker.getElement().style.visibility = "hidden";
        });

        hotels.forEach((hotel) => {
          if (hotel.longitude && hotel.minTripPrice < 1000000) {
            const rateFormatted = displayPrice(
              hotel.minTripPrice,
              this.currency
            );
            const baseRateFormatted = displayPrice(
              hotel.minBaseTripPrice,
              this.currency
            );

            const hotelHtml = `<a title=
							'Best trip cost with selected filters\n${rateFormatted} (${displayPrice(
              hotel.minTripPrice / hotel.tripLength,
              this.currency
            )} daily)\nBest trip cost next 12 months\n${baseRateFormatted} (${Math.round(
              (hotel.minTripPrice / hotel.minBaseTripPrice - 1) * 100
            )}% discount)'
							href='${
                this.$router.resolve({
                  name: "hotels-id",
                  params: { id: hotel.id },
                }).href
              }'>
									<span>${hotel.brand}</span><br>
									${capitalize(hotel.name)}<br>
									${hotel.country} &ndash;
									<b>${rateFormatted}</b>
								</a>`;

            var popup = this.popupsCache[hotel.id];

            if (!popup) {
              popup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false,
                anchor: "center",
              })
                .setHTML("")
                .setLngLat([hotel.longitude, hotel.latitude])
                .addTo(this.map);

              // Allow scrolling when mouse is over popup

              popup.getElement().addEventListener("wheel", (event) => {
                event.preventDefault();

                document
                  .getElementsByClassName("mapboxgl-canvas-container")[0]
                  .dispatchEvent(
                    //@ts-ignore
                    new event.constructor(event.type, event)
                  );
              });

              popup.getElement().addEventListener("click", (event) => {
                if (!event.ctrlKey && !event.metaKey) {
                  event.preventDefault();
                  this.$router.push({
                    name: "hotels-id",
                    params: { id: hotel.id },
                    prefetch: true,
                  });
                }
              });

              popup.getElement().style.visibility = "hidden";
              popup.getElement().style.opacity = "0";

              this.popupsCache[hotel.id] = popup;
            }

            popup.setHTML(hotelHtml);

            this.popups.push(popup);

            toKeep.push(hotel.id);
          }
        });

        // hide inactive popups in the cache
        Object.keys(this.popupsCache).forEach((id) => {
          if (!toKeep.includes(id)) {
            this.popupsCache[id].getElement().style.opacity = "0";
            this.popupsCache[id].getElement().style.visibility = "hidden";
          }
        });

        this.zoomendMap(this.map);

        // only show markers for the most expensive hotels
        hotels.slice(0, 300).forEach((hotel) => {
          if (!this.markers[hotel.id]) {
            var el = document.createElement("div");
            el.className = "marker";

            var marker = new mapboxgl.Marker(el)
              .setLngLat([hotel.longitude, hotel.latitude])
              .addTo(this.map);

            this.markers[hotel.id] = marker;
          }

          this.markers[hotel.id].getElement().style.visibility = "visible";
        });
      }
    }, 1),
    zoomendMap: _.debounce(function (map) {
      if (this.popups) {
        map.resize();

        this.popups.forEach((popup) => {
          popup.getElement().style.opacity = "0";
          popup.getElement().style.visibility = "hidden";
        });

        const visiblePopups = [];

        this.popups
          .slice()
          .reverse()
          .forEach((popup) => {
            let popupElement = popup.getElement();
            let isThereCollission = false;

            visiblePopups.forEach((popup2) => {
              var popupElement2 = popup2.getElement();

              if (popupElement != popupElement2) {
                if (
                  collision(popupElement, popupElement2) &&
                  popupElement2.style.visibility === "visible"
                ) {
                  isThereCollission = true;
                }
              }
            });

            if (!isThereCollission) {
              popup.getElement().style.visibility = "visible";
              popup.getElement().style.opacity = "1";
              visiblePopups.push(popup);
            }
          });
      }
    }, 1),
    saveMap(map) {
      this.map = map;
      this.searchExpression = "";
      this.zoomendMap(map);
    },
  },
};
</script>

<style></style>
