import Vue from "vue";
import VueRouter from "vue-router";
import Index from "./pages/Index.vue";
import Map from "./pages/Map.vue";
import Terms from "./pages/Terms.vue";
import Privacy from "./pages/Privacy.vue";
import Directory from "./pages/Directory.vue";

import Hotel from "./pages/hotels/_id.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Index,
  },
  {
    path: "/map",
    name: "map",
    component: Map,
  },
  {
    path: "/hotels/:id",
    name: "hotels-id",
    component: Hotel,
  },
  {
    path: "/terms",
    component: Terms,
  },
  {
    path: "/privacy",
    component: Privacy,
  },
  {
    path: "/directory",
    component: Directory,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
