<template>
  <div class="landing">
    <Menu></Menu>

    <header class="container mt-20 mb-20 text-center">
      <div>
        <h1 class="big mb-3">HotelMap</h1>
        <div class="mb-5 text-muted lead">
          Cheapest hotel rates for a flexible traveller on a map
        </div>
        <div class="mb-4">
          <router-link to="/map" class="btn btn-lg btn-dark"
            >See the map</router-link
          >
        </div>
        <div>
          <a href="#explanation" class="external-link">Learn More</a>
        </div>
      </div>
    </header>

    <section id="explanation" class="container mb-5">
      <div>
        <div
          class="row justify-content-center justify-content-md-between align-items-center"
        >
          <div
            class="mb-15 mb-md-0 col-12 col-md-5 col-xl-6 d-flex justify-content-center"
          >
            <img src="/static/map.svg" alt class="img-fluid" />
          </div>
          <div class="col-md-7 col-xl-6">
            <h2 class="col-lg-11 small">
              The best hotel deals in the whole world
            </h2>
            <div class="mt-4 col-lg-9 text-adaptive">
              Get inspired as you browse a map with the most remarkable hotels.
              Easily compare their prices including taxes in your home currency
              and find the perfect destination for your trip.
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container">
        <div
          class="row justify-content-center justify-content-md-between align-items-center"
        >
          <div class="col-md-7 col-xl-6">
            <h2 class="col-lg-11 small">
              See how seasons affect the hotel prices
            </h2>
            <div class="mt-4 col-lg-9 text-adaptive">
              Hotel prices change throughout the year, and sometimes the best
              deals are hidden in the plain sight.
            </div>
          </div>
          <div
            class="mb-15 mb-md-0 col-12 col-md-5 col-xl-6 d-flex justify-content-center"
          >
            <img src="/static/chart.svg" alt class="img-fluid" />
          </div>
        </div>
      </div>
    </section>

    <section class="mb-20 mt-20 container text-center">
      <h2 class="small">See the best hotel rates for your trips</h2>
      <router-link to="/map" class="btn btn-lg btn-dark mt-5" preload
        >Start exploring</router-link
      >
    </section>

    <b-container>
      <Footer></Footer>
    </b-container>
  </div>
</template>

<script>
// import Vue from "vue";

import Menu from "../components/Menu.vue";
import Footer from "../components/Footer.vue";

export default {
  layout: "keepalive",
  mounted() {
    document.title = `HotelMap`;
  },
  head() {
    return {
      meta: [
        // hid is used as unique identifier. Do not use `vmid` for it as it will not work
        {
          hid: "description",
          name: "description",
          content:
            "Cheapest hotel rates for a flexible traveller on a map. Get inspired as you browse a map with the most remarkable hotels. Easily compare their prices at sight and find the perfect destination for your trip. Hotel prices change throughout the year, and sometimes the best deals are hidden in the plain sight.",
        },
      ],
    };
  },
  data() {
    return {};
  },
  components: {
    Menu,
    Footer,
  },
};
</script>

<style lang="scss">
.landing {
  .navbar {
    position: unset;
  }

  .mb-10 {
    margin-bottom: 6rem !important;
  }

  .mt-10 {
    margin-top: 6rem !important;
  }

  .mb-20 {
    margin-bottom: 12rem !important;
  }

  .mt-20 {
    margin-top: 12rem !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: 700;
  }

  h1,
  h2 {
    font-size: 3rem;
    line-height: 3rem;
  }
  h1.big,
  h2.big {
    font-size: 72px;
    line-height: 86px;
  }
  h1.small,
  h2.small {
    font-size: 42px;
    line-height: 52px;
    font-weight: 700;
  }

  h3 {
    font-size: 42px;
    line-height: 52px;
  }

  /* Smaller than 1200px */
  @media (max-width: 1199.98px) {
    h1 br,
    h2 br,
    h3 br,
    .text-adaptive br {
      display: none;
    }

    h1,
    h2 {
      font-size: 52px;
      line-height: 62px;
    }
    h1.big,
    h2.big {
      font-size: 62px;
      line-height: 72px;
    }
    h1.small,
    h2.small {
      font-size: 36px;
      line-height: 46px;
    }

    h3 {
      font-size: 36px;
      line-height: 46px;
    }
  }
  /* Smaller than 992px */
  @media (max-width: 991.98px) {
    h1,
    h2 {
      font-size: 42px;
      line-height: 52px;
    }
    h1.big,
    h2.big {
      font-size: 52px;
      line-height: 62px;
    }
    h1.small,
    h2.small {
      font-size: 32px;
      line-height: 42px;
    }

    h3 {
      font-size: 32px;
      line-height: 42px;
    }
  }
  /* Smaller than 768px */
  @media (max-width: 767.98px) {
    h1,
    h2 {
      font-size: 32px;
      line-height: 42px;
    }
    h1.big,
    h2.big {
      font-size: 42px;
      line-height: 52px;
    }
    h1.small,
    h2.small {
      font-size: 26px;
      line-height: 36px;
    }

    h3 {
      font-size: 26px;
      line-height: 36px;
    }

    .text-adaptive.f-24 {
      font-size: 20px;
      line-height: 30px;
    }
    .text-adaptive.f-22 {
      font-size: 18px;
      line-height: 28px;
    }
    .text-adaptive.f-20 {
      font-size: 16px;
      line-height: 26px;
    }
    .text-adaptive.f-18 {
      font-size: 16px;
      line-height: 26px;
    }
  }
  /* Smaller than 576px */
  @media (max-width: 575.98px) {
    h1,
    h2 {
      font-size: 26px;
      line-height: 36px;
    }
    h1.big,
    h2.big {
      font-size: 32px;
      line-height: 42px;
    }
    h1.small,
    h2.small {
      font-size: 22px;
      line-height: 32px;
    }
  }
}
</style>
