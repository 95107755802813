import Vue from "vue";
import Vuex from "vuex";

// import flights from '~/data/flights.json'
import allHotels from "../published_hotels.json";

Vue.use(Vuex);

const store = new Vuex.Store({
  mutations: {
    SET_TRIP_LENGTH(state, tripLength) {
      if (tripLength && tripLength <= 60) state.tripLength = tripLength;
    },
    SET_SEASON_ONLY(state, seasonOnly) {
      state.seasonOnly = seasonOnly;
    },
    SET_MONTHS(state, months) {
      state.months = months;
    },
  },

  getters: {
    computed: (state) => {
      let absoluteMaxTripPrice = 0;
      const hotelBrands = {};

      allHotels.forEach((hotel) => {
        if (
          hotel.pricesUsd &&
          Object.keys(hotel.pricesUsd).length > state.tripLength
        ) {
          hotelBrands[hotel.brand] = (hotelBrands[hotel.brand] || 0) + 1;

          const dates = Object.keys(hotel.pricesUsd).sort();

          const firstDate = new Date(dates[0]).getTime();
          const lastDate = new Date(dates.slice(-1)[0]).getTime();

          const dayCount = (lastDate - firstDate) / 86400000;

          // ==============

          hotel.taxRate = state.tax
            ? typeof hotel.taxRate === "undefined"
              ? 0
              : hotel.taxRate
            : 0;

          hotel.unpacked = []; // hydrate the data / balance between initial load and constitent data

          for (let day = 0; day < dayCount; day++) {
            const date = new Date(firstDate + 86400000 * day);
            const dateString = date.toJSON().slice(0, 10);

            const rateUnavailable = !hotel.pricesUsd[dateString];
            const offSeason =
              state.seasonOnly && hotel.weather[date.getMonth()] == 0;
            const selectedMonth = state.months.includes(date.getMonth());

            hotel.unpacked.push({
              date: dateString,
              baseRate: rateUnavailable
                ? 1000000
                : hotel.pricesUsd[dateString] * (1 + hotel.taxRate),
              adjustedRate:
                offSeason || rateUnavailable || !selectedMonth
                  ? 1000000
                  : hotel.pricesUsd[dateString] * (1 + hotel.taxRate),
            });

            if (rateUnavailable) hotel.pricesUsd[dateString] = null;
          }

          // ==============
          hotel.tripLength = state.tripLength;

          hotel.adjustedTripPrices = {};
          hotel.baseTripPrices = {};

          /* /let flight = Math.min(hotel.nearestRelevantAirports.map(airportCode => {
					const flightPrices = flights.coach.VIE[airportCode]
					const nieco = flights.coach.VIE[airportCode][Object.keys(flightPrices)[0]]

					//console.log(flights.coach.VIE[airportCode][Object.keys(flightPrices)[0]][Object.keys(nieco)[0]].price)

					return flights.coach.VIE[airportCode][Object.keys(flightPrices)[0]][Object.keys(nieco)[0]].price
				}))
				// */

          let baseRateSum = 0;
          let adjustedRateSum = 0;

          for (let offset = 0; offset < state.tripLength; offset++) {
            baseRateSum += hotel.unpacked[offset].baseRate;
            adjustedRateSum += hotel.unpacked[offset].adjustedRate;
          }

          for (
            let day = 0;
            day < hotel.unpacked.length - state.tripLength;
            day++
          ) {
            hotel.baseTripPrices[hotel.unpacked[day].date] =
              Math.round(baseRateSum);
            baseRateSum -= hotel.unpacked[day].baseRate;
            baseRateSum += hotel.unpacked[day + state.tripLength].baseRate;

            hotel.adjustedTripPrices[hotel.unpacked[day].date] =
              Math.round(adjustedRateSum);
            adjustedRateSum -= hotel.unpacked[day].adjustedRate;
            adjustedRateSum +=
              hotel.unpacked[day + state.tripLength].adjustedRate;
          }

          const baseTripPricesValues = Object.values(
            hotel.baseTripPrices
          ).filter((value) => value < 100000);
          hotel.minBaseTripPrice = Math.round(
            Math.min(...baseTripPricesValues)
          );
          hotel.maxBaseTripPrice = Math.round(
            Math.max(...baseTripPricesValues)
          );

          const adjustedTripPricesValues = Object.values(
            hotel.adjustedTripPrices
          ).filter((value) => value < 100000);
          hotel.minTripPrice = Math.round(
            Math.min(...adjustedTripPricesValues)
          );
          hotel.maxTripPrice = Math.round(
            Math.max(...adjustedTripPricesValues)
          );
          absoluteMaxTripPrice = Math.max(
            absoluteMaxTripPrice,
            hotel.maxTripPrice
          );

          const hotelPricesUsdFiltered = Object.values(hotel.pricesUsd).filter(
            (val) => val !== null
          );
          hotel.minRate = Math.round(
            Math.min(...hotelPricesUsdFiltered) * (1 + hotel.taxRate)
          );
          hotel.maxRate = Math.round(
            Math.max(...hotelPricesUsdFiltered) * (1 + hotel.taxRate)
          );
          hotel.averageDailyRate = Math.round(
            (hotelPricesUsdFiltered.reduce((a, b) => a + b) /
              hotelPricesUsdFiltered.length) *
              (1 + hotel.taxRate)
          );
        }
      });

      allHotels.sort(
        (a, b) =>
          Math.round(b.minTripPrice || 0) - Math.round(a.minTripPrice || 0)
      );

      return {
        allHotels,
        maxTripPrice: absoluteMaxTripPrice,
        brands: hotelBrands,
      };
    },
  },

  state: {
    // add to state days to precompute for
    tax: true,
    tripLength: 7,
    seasonOnly: false,
    months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  },
});

export default store;
